<script>
import { api } from '@/api'
import XSteps from '@/components/x-steps.vue'
import XBanner1 from '@/components/x-banner-1.vue'
import XTrustpilot from '@/components/x-trustpilot.vue'

export default {
  data() {
    const editor = this.$route.params.editor || 0

    return {
      editor,
      email: this.$route.params.email,
      steps: [
        {
          title: 'Küchenanfrage',
          text:
            'Beantworte uns in 3 Minuten ein paar Fragen zu Deiner Küche und erkläre uns, wie Du Deine Küche ausstatten willst',
          done: true,
        },
        {
          title: 'Raummaße',
          text:
            'Um dir ein genaues Angebot machen zu können, benötigen wir jetzt im nächsten Schritt nur noch die Maße Deiner Küche. Danach übernehmen wir.',
          done: editor === 1,
        },
        {
          title: 'Angebot',
          text:
            'Innerhalb von 24 Stunden erhältst Du von uns ein unverbindliches Angebot. Dafür werden wir Dich per WhatsApp oder Telefon vorab kontaktieren, um kurz in 5 Minuten Deine finalen Details zu besprechen..',
        },
      ],
    }
  },
  methods: {
    gotoEditor() {
      api('journey', 'find', {
        url: '/find',
        method: 'POST',
        data: { email: this.email },
      })
        .then(data => {
          const journey = data && data.journey && JSON.parse(data.journey)

          return api('journey', 'save', {
            method: 'POST',
            data: {
              ...data,
              journey: JSON.stringify({
                ...journey,
                step: 12 - 1,
              }),
            },
          })
        })
        .then(() => {
          this.$router.push({ name: 'wizard', query: { email: this.email } })
        })
    },
  },
  components: {
    XSteps,
    XBanner1,
    XTrustpilot,
  },
}
</script>
<template>
  <div class="px-4 px-md-12 pb-10 pb-md-16">
    <div class="mx-auto pt-10 pt-md-16" style="max-width: 644px;">
      <h5 class="mb-4 text-h6 font-weight-bold text-raleway text-center">
        Glückwunsch!
      </h5>

      <p
        class="mb-6 mx-auto font-weight-light text-center"
        style="max-width: 45ch;"
      >
        Wir haben Deine Anfrage erhalten und Du bist Deiner Traumküche ein Stück
        näher gekommen
      </p>

      <img
        src="/statics/wizard/thankyou-hero.png"
        alt="Hero image"
        width="644"
        height="300"
        class="img-fluid mb-6"
      />

      <p v-if="editor === 0" class="mb-0 font-weight-light text-center">
        Um Dir ein unverbindliches Angebot zukommen zu lassen, benötigen wir von
        Dir die Raummaße und eine Grundrisskizze,
        <u class="cursor-pointer" @click="gotoEditor">
          Jetzt Grundriss zeichen
        </u>
      </p>
    </div>

    <template v-if="editor === 0">
      <p class="mt-10 mt-md-16 font-weight-bold text-center">
        Was passiert als nächstes?
      </p>

      <x-steps class="mb-10" :steps="steps" :current="0"></x-steps>

      <x-banner-1
        class="mx-auto mt-10 mt-md-16"
        style="max-width: 866px;"
      ></x-banner-1>
    </template>
    <template v-else>
      <x-banner-1
        class="mx-auto mt-8 mt-md-12"
        style="max-width: 866px;"
      ></x-banner-1>

      <p class="mt-10 mt-md-16 font-weight-bold text-center">
        Was passiert als nächstes?
      </p>

      <x-steps class="mb-10" :steps="steps" :current="0"></x-steps>
    </template>

    <p class="mt-10 mt-md-16 font-weight-bold text-center">
      Unsere Kunden sind begeistert!
    </p>

    <x-trustpilot class="mx-auto mb-8 mb-md-10" style="max-width: 1150px;" />
  </div>
</template>
