<script>
const chunk = (array, size) => {
  const chunked_arr = []
  let index = 0
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index))
    index += size
  }
  return chunked_arr
}

export default {
  data() {
    return {
      model: 0,
      allReviews: [
        {
          rating: 5,
          title: 'Sie planten meine Küche für mich in 3 ...',
          body:
            'Sie planten eine Küche für mich in 3 Tagen und lieferten sie innerhalb einer Woche, ich bin sehr zufrieden mit dem Ergebnis',
        },
        {
          rating: 5,
          title: 'Tolle Produkte bei top Service und Flexibilität',
          body:
            'Hat alles sehr gut geklappt. Passende Küche ist schnell ausgewählt, Prozess geht schnell und schon war sie da. Super Service und top Beratung. Werde in Zukunft ganz bestimmt keine Küche mehr für eine Mietwohnung kaufen - KitchX bietet da die perfekte Alternative für jeden mit Mietwohnung',
        },
        {
          rating: 5,
          title: 'Echt starkes Angebot!',
          body:
            'Echt stark, hat alles super geklappt! Die Küche ist sehr hochwertig und war in 3 Tagen da. Der Aufbauservice hat alles super erledigt, war freundlich, hat den Müll wieder mitgenommen und alles sauber hinterlassen. Tolles Angebot, vielen Dank an das ganze Team!',
        },
        {
          rating: 5,
          title: 'Klare Empfehlung',
          body:
            'Ich habe mich für KitchX entschieden, da der Preis und die Lieferzeiten unschlagbar waren. Die Küche sieht echt toll aus und der Service mit Lieferung und Aufbau der Küche sowie Entsorgung der alten Küche war echt super. Der Austausch war durchgehend unkompliziert. Ich kann KitchX nur empfehlen.',
        },
        {
          rating: 5,
          title: 'Sehr zufrieden',
          body:
            'Ich bin vor kurzem umgezogen und hab dringend eine neue Küche benötigt. Durch einen Bekannten bin ich auf KitchX gestoßen. Ich muss wirklich sagen, die Lieferzeit und Montage waren super schnell und unkompliziert. Man merkt auch, dass die Materialien hochwertig sind und auf Qualität geachtet wird.',
        },
        {
          rating: 5,
          title: 'Die Jungs sind einfach geil',
          body:
            'Lasse und Marco sind einfach coole Dudes, sofort per Du, unkompliziert, kompetent und rund um Uhr erreichbar. Unsere KitchX Black sieht einfach top aus, danke Jungs!',
        },
        {
          rating: 5,
          title: 'Unsere Erfahrung mit KitchX war…',
          body:
            'Unsere Erfahrung mit KitchX war wirklich sehr gut! Marco und Lasse waren stets erreichbar und haben uns super in der Vorbereitung und beim Aufbau der Küche in Kommunikation mit den Handwerkern unterstützt. Man fühlt sich im ganzen Prozess sehr gut betreut, deshalb können wir KitchX guten Gewissens weiterempfehlen. Danke an die Jungs!',
        },
        {
          rating: 5,
          title: 'Einfach, schnell, gute Qualität',
          body:
            'Sehr guter, schneller Kundenservice. Die Küche ist schön und funktional und vor allem schnell liefer- und einbaubar.',
        },
        {
          rating: 4,
          title: 'Top Service, geile Qualität',
          body:
            'Super Service, geile Küche. Gerne wieder. 4 von 5 Sternen weil die Küche anstatt in 2 in 3 Wochen kam. Aber die Qualität und die Jungs von KitchX sind echt super. Alle anderen Küchenstudios, bei denen ich Vergleichsangebote eingeholt hab waren teurer und meinten, ich muss 3 Monate auf meine Küche warten...',
        },
      ],
    }
  },
  computed: {
    reviewsChunk() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return chunk(this.allReviews, 1)
        case 'sm':
          return chunk(this.allReviews, 2)
        default:
          return chunk(this.allReviews, 3)
      }
    },
  },
}
</script>
<template>
  <v-carousel
    v-model="model"
    light
    hide-delimiters
    :height="$vuetify.breakpoint.smAndDown ? 400 : 340"
    class="carousel-brand-1"
  >
    <v-carousel-item
      v-for="(reviews, chunkIndex) in reviewsChunk"
      :key="chunkIndex"
    >
      <div
        class="px-11"
        style="
          height: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          grid-gap: 1.5rem;
        "
      >
        <div
          v-for="(review, reviewIndex) in reviews"
          :key="reviewIndex"
          class="px-4 py-5 rounded-lg"
          style="
            position: relative;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
          "
        >
          <div
            class="mb-1"
            alt="Rating"
            style="height: 24px; background: url('/statics/rating-stars-yellow.png');"
            :style="{ width: `${(review.rating * 136) / 5}px` }"
          ></div>
          <p class="font-weight-bold" style="max-width: 200px;">
            {{ review.title }}
          </p>
          <p style="font-size: 14px;" v-html="review.body"></p>
          <img
            src="/statics/Trustpilot_logo-1.png"
            width="100"
            height="41"
            alt="Trustpilot logo"
            class="mb-3"
            style="position: absolute; bottom: 0;"
          />
        </div>
      </div>
    </v-carousel-item>
    <template v-slot:prev="{ on, attrs }">
      <v-btn color="primary" x-small v-bind="attrs" v-on="on" fab depressed>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn color="primary" x-small v-bind="attrs" v-on="on" fab depressed>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
  </v-carousel>
</template>
